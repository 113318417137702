.wtdc-select-selector {
  display: flex;
}
.ant-modal-root{
  .ant-modal-content{
    border-radius: 10px;
    .ant-modal-close-x{
      color: #999999;
    }
    .ant-modal-header{
      height: 55px;
      background: #F1F2F6;
      border-radius: 10px 10px 0 0;
    }
    .ant-modal-footer{
      height: 55px;
      display: flex;
      border-radius: 0 0 10px 10px;
      background: #F1F2F6;
      align-items: center;
      justify-content: center;
      .ant-btn:first-child{
        margin-right: 20px;
      }
    }
  }
}
.ant-input-suffix{
  color: #999;
}
.ant-table-cell{
  &::before{
    height: 0px !important;
  }
}
